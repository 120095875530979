:root {
    --p-color: #FFB82E;
    --s-color: #F9F9F9;
    --bgc2: #EAEAEA;

    --bc: #000000;
    --wc: #ffffff;
    --wc2: #dddddd;

    --mh-tc: #000;
    --place-tc: #3f3f3f;

    --p-tc: #0D0D0D;
    --s-tc: #727272;
    --c-tc: #2b2b2b;

    --bgc: #F9F9F9;
    --bg-menu: #0D0D0D;

    --stroke: 0.06vw #ABABAB;

    /* Dark mode colors */
    --D-p-color: #FFB82E;
    --D-s-color: #F9F9F9;
    --D-bgc2: #EAEAEA;

    --D-bc: #000000;
    --D-wc: #ffffff;
    --D-wc2: #dddddd;

    --D-mh-tc: #000;
    --D-place-tc: #3f3f3f;

    --D-p-tc: #e6e6e6;
    --D-s-tc: #727272;
    --D-c-tc: #2b2b2b;

    --D-bgc: #F9F9F9;
    --D-bg-menu: #0D0D0D;

    --D-stroke: 0.06vw #ABABAB;
    /* Dark mode colors ends */

    /* container */
    --container: 71.75vw;

    /* font */
    --font: "Neue Regrade";

    /* text */
    --fs: 1.05vw; /* 16px */
    --fs-hover: 2.05vw; /* 16px */
    --fscon: 1.8vw;
    --fsc: 2.1vw;
    --fsp: 6.55vw;
    --fsm: 1.31vw; /* 20px */
    --fssm: 2vw; /* 30px */
    --fsp-head: 2.54vw; /* 39px */
    --fs-head: 3.2vw; /* 49px */
    --fs-para: 0.94vw; /* 14px */

    /* margin */
    --top-m: 2.3vw; /* 35px */
    --header-b-m: 1.54vw;
    --subhead-bm: 1.31vw;
    --mrb-10: 0.655vw; /* 10px */

    /* transition */
    --t-time: 0.6s;
  }


  /* mobile responsive */
  @media (max-width: 767px) {
    :root {
      --fs: 2.717vw; /* 16px */
      --fs-head: 7vw;
    }
  }